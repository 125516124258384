import React, { useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';

import { granularities, kpis } from 'utils/constants/metrics';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  Button,
  Checkbox,
  MultipleSelect,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  nameError: false,
  keyError: false,
  viewKeyError: false
};
export const ModalForm = props => {
  const { config, onValidate, onClose, actionName, views, metrics } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config?.name || '',
    key: config?.key || '',
    description: config?.description,
    viewKey: config?.viewKey,
    features: config?.features || {},
    showInSimplified: config?.showInSimplified ?? true,
    funnelKpis: config?.funnelKpis || [],
    defaultFunnelKpis: config?.defaultFunnelKpis || [],
    funnelGranularities: config?.funnelGranularities || [],
    defaultFunnelGranularities: config?.defaultFunnelGranularities || []
  };
  const [
    {
      name,
      key,
      description,
      features,
      viewKey,
      showInSimplified,
      funnelKpis,
      defaultFunnelKpis,
      funnelGranularities,
      defaultFunnelGranularities
    },
    setState
  ] = useState(initialState);

  const [{ nameError, keyError, viewKeyError }, setError] = useState(
    ErrorState
  );

  const handleChange = field => value => {
    let newValue = value;
    if (typeof value === 'string') {
      newValue = value.trim();
    }
    setState(prevState => {
      return { ...prevState, [field]: newValue };
    });
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const doAction = () => {
    let error = false;

    if (!name || name.trim().length === 0) {
      toggleError('name', true);
      error = true;
    }

    if (!key || key?.trim().length === 0) {
      toggleError('key', true);
      error = true;
    }
    if (!viewKey || viewKey?.trim().length === 0) {
      toggleError('viewKey', true);
      error = true;
    }

    const datas = {
      name,
      key,
      description,
      showInSimplified,
      features,
      viewKey,
      funnelKpis,
      defaultFunnelKpis: defaultFunnelKpis.filter(el => {
        if (funnelKpis.length > 0) {
          return funnelKpis.includes(el);
        }
        return true;
      }),
      funnelGranularities,
      defaultFunnelGranularities: defaultFunnelGranularities.filter(el => {
        if (funnelGranularities.length > 0) {
          return funnelGranularities.includes(el);
        }
        return true;
      })
    };
    if (!error) {
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le profil
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const disableFields = !!config;

  const featuresMapping = [
    { key: 'canEditPAL', label: 'Edition des PALs' },
    { key: 'launchCampaign', label: 'Lancer une campagne' },
    { key: 'canAccessBO', label: 'Accéder au BO' },
    { key: 'isSimplified', label: 'Accéder au BO simplifié' },
    { key: 'enabledPmlFront', label: 'Setup PML en Front' },
    // { key: 'accessContent', label: 'Accèder au Creative Builder' },
    {
      key: 'sendCreationEmailOnCampaignValidation',
      label: "Création d'utilisateur à la première campagne"
    },
    {
      key: 'simpleReport',
      label: 'Simplification du bilan (Vue PML Uniquement)'
    },
    {
      key: 'hideActivationObjective',
      label: 'Masquer objectif activation'
    },
    {
      key: 'removeCampagnEndPage',
      label: 'Suppression page lancement de campagne'
    },
    {
      key: 'activationAdAccountAndPages',
      label: 'Ajout comptes/pages pub dans Activation'
    }
  ];

  const featuresMappingContent = [
    { key: 'accessContent', label: 'Accèder au Creative Builder' },
    {
      key: 'accessTemplate',
      label: 'Accèder au Template'
    }
  ];

  const pmlSectionMapping = [
    { key: 'inProgressSection', label: 'Section Campagnes lancées' },
    { key: 'scheduledSection', label: 'Section Campagnes programmées' },
    { key: 'palSection', label: 'Section PAL' },
    { key: 'draftsSection', label: 'Section Campagnes à finaliser' },
    { key: 'spendingsSection', label: 'Section Dépenses' },
    {
      key: 'newCampaignsSection',
      label: 'Section Création de nouvelle campagne'
    }
  ];

  const funnelSectionMapping = [
    { key: 'hideKpiSelect', label: 'Masquer le select de KPIs' },
    {
      key: 'hideGranularitySelect',
      label: 'Masquer le select de granularités'
    },
    {
      key: 'hideGranularityFilter',
      label: 'Masquer le filtre par granularité'
    },
    { key: 'hideExport', label: 'Masquer le bouton export' },
    { key: 'hideBarChart', label: 'Masquer le bar chart' },
    {
      key: 'hideLineChart',
      label: 'Masquer le line chart'
    }
  ];

  const getCheckbox = item => {
    return (
      <Item xs={6} justify="flex-start">
        <Row spacing={0} justify="flex-start">
          <Item xs={11} justify="flex-start">
            <Checkbox
              onChange={event => {
                handleChange('features')({
                  ...features,
                  [item.key]: event.target.checked
                });
              }}
              checked={features[item.key]}
            />
            <Text>{item.label}</Text>
          </Item>
        </Row>
      </Item>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={nameError ? 'error' : 'inherit'}>
                          Nom du profil *
                        </Text>
                        <TextField
                          label=""
                          error={nameError}
                          onChange={handleChange('name')}
                          value={name}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={keyError ? 'error' : 'inherit'}>
                          Key du profil *
                        </Text>
                        <TextField
                          label=""
                          error={keyError}
                          onChange={handleChange('key')}
                          value={key}
                          disabled={disableFields}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={viewKeyError ? 'error' : 'inherit'}>
                          Vue *
                        </Text>
                        <Select
                          disabled={disableFields}
                          label=""
                          error={viewKeyError}
                          options={views}
                          onChange={handleChange('viewKey')}
                          value={viewKey}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text>
                          Afficher les utilisateurs du profil dans le BO
                          simplifié
                        </Text>
                        <Switch
                          onChange={handleChange('showInSimplified')}
                          checked={showInSimplified}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={12} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text>Description</Text>
                        <TextField
                          label=""
                          onChange={handleChange('description')}
                          value={description}
                          multiline
                          rows={3}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Droits accordés au profil" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  {featuresMapping.map(item => getCheckbox(item))}
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Droits accordés au Module Contenus" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  {featuresMappingContent.map(item => getCheckbox(item))}
                </Row>
              </Container>
            </FormSection>
            {viewKey === 'pml' && (
              <FormSection
                title="Sections affichées dans le dashboard PML"
                xs={3}
              >
                <Container>
                  <Row spacing={0} justify="flex-start">
                    {pmlSectionMapping.map(item => getCheckbox(item))}
                  </Row>
                </Container>
              </FormSection>
            )}
            <FormSection
              title="Affichage funnel (performance médias / PML)"
              xs={3}
            >
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <MultipleSelect
                          title="KPIs séléctionnables"
                          placeHolder="Tous les KPIs"
                          selectAll
                          onChange={options => {
                            handleChange('funnelKpis')(
                              options.map(el => el.key)
                            );
                          }}
                          options={
                            metrics?.kpis?.map(el => {
                              return { key: el, label: kpis[el] || el };
                            }) || []
                          }
                          selected={funnelKpis}
                          small
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <MultipleSelect
                          title="KPIs affichés"
                          placeHolder="Par défaut"
                          selectAll
                          onChange={options => {
                            handleChange('defaultFunnelKpis')(
                              options.map(el => el.key)
                            );
                          }}
                          options={(funnelKpis.length !== 0
                            ? funnelKpis
                            : metrics?.kpis
                          )?.map(el => {
                            return { key: el, label: kpis[el] || el };
                          })}
                          selected={defaultFunnelKpis}
                          small
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <MultipleSelect
                          title="Granularités séléctionnables"
                          placeHolder="Toutes les granularités"
                          onChange={options => {
                            handleChange('funnelGranularities')(
                              options.map(el => el.key)
                            );
                          }}
                          options={
                            metrics?.granularities.map(el => {
                              return {
                                key: el,
                                label: granularities[el] || el
                              };
                            }) || []
                          }
                          selected={funnelGranularities}
                          selectAll
                          small
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <MultipleSelect
                          title="Granularités affichées"
                          placeHolder="Par défaut"
                          onChange={options => {
                            handleChange('defaultFunnelGranularities')(
                              options.map(el => el.key)
                            );
                          }}
                          options={
                            (funnelGranularities.length > 0
                              ? funnelGranularities
                              : metrics?.granularities
                            ).map(el => {
                              return {
                                key: el,
                                label: granularities[el] || el
                              };
                            }) || []
                          }
                          selected={defaultFunnelGranularities}
                          small
                          selectAll
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  {funnelSectionMapping.map(item => getCheckbox(item))}
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalForm;
