import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import allCountries from 'utils/constants/countries';
import { isValidEmail, parseCustomLabel } from 'utils/functions';
import numeral from 'utils/numeral';
import { ACTIVATION, CUSTOMERS, GROUP, TRANSACTION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Alert, HelpTooltip, Table, Text } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  IconButton,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';
import Loading from 'components/Loading';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { AddChildCustomer, UpdateChildCustomer } from './components/modalForm';
import { CustomerActionsTypes, useCustomer } from './contexts/customContext';
import {
  CreateCustomerModal,
  DeleteCustomerModal,
  EditableTable
} from './components';
import { isParent, sortByDate } from './utils';

export default () => {
  const [
    { customers, presets, countries, selectedCustomer },
    dispatchCustomer
  ] = useCustomer();
  const { adminRights, isSuperAdmin } = useUser();
  const UNAUTHORIZED = 'unauthorized';
  const [customerInfo, setCustomerInfo] = useState({});
  const [subAccounts, setSubAccounts] = useState([]);
  const [constraint, setConstraint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedParent, setSelectedParent] = useState(null);
  const [numberOfGroupes, setNumberOfGroupes] = useState(0);
  const [parents, setParents] = useState();
  const [selectedSubAccounts, setSelectedSubAccounts] = useState([]);
  const [financialAdministrators, setFinancialAdministrators] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allFees, setAllFees] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [customLabel, setCustomLabel] = useState('');
  const [modal, setModal] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('platform');
  const [balance, setBalance] = useState(null);
  const [isEditingBalance, setIsEditingBalance] = useState(false);
  const [newBalance, setNewBalance] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [budgetAlerts, setBudgetAlert] = useState(0);
  const [emailsToAlert, setEmailsToAlert] = useState([]);
  const [autoSendInvoice, setAutoSendInvoice] = useState(false);

  const { themeColors } = useTheme();
  const [err, setErr] = useState({ show: false });

  useEffect(() => {
    setBudgetAlert(selectedCustomer?.budgetAlerts?.budget || 0);
    setEmailsToAlert(selectedCustomer?.budgetAlerts?.emails || []);
  }, [selectedCustomer]);

  const initContext = async () => {
    const allCustomers = await CUSTOMERS.get();
    const allGroups = await GROUP.getGroups({
      custom: ['key', 'customerName', 'name']
    });
    const allActivableMedias = await ACTIVATION.getActivableMedias();

    const filteredGroups = allGroups.filter(el =>
      allCustomers?.every(r => r.groupId !== el._id)
    );
    const allPresets = await ACTIVATION.getActivationPresets();

    allCustomers.map(customer => {
      const group = allGroups.find(r => r._id === customer.groupId);
      // eslint-disable-next-line no-param-reassign
      customer.group = group;
      return customer;
    });

    const sortedCustomers = sortByDate(allCustomers);
    let localSelectedCustomer;
    if (sortedCustomers?.[0]?._id) {
      localSelectedCustomer = await CUSTOMERS.getStripeCustomerById(
        sortedCustomers[0]._id
      );
    }

    setCustomerInfo(localSelectedCustomer?.info);
    setAutoSendInvoice(localSelectedCustomer?.autoSendInvoice);
    setBudgetAlert(localSelectedCustomer?.budgetAlerts?.budget || 0);
    setEmailsToAlert(localSelectedCustomer?.budgetAlerts?.emails || []);
    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        presets: allPresets,
        customers: sortedCustomers,
        selectedCustomer: localSelectedCustomer,
        groups: filteredGroups,
        selectedGroup: allGroups.find(
          group => group._id === localSelectedCustomer?.groupId
        ),
        countries: allCountries.sort((a, b) => a.label.localeCompare(b.label)),
        activableMedias: allActivableMedias
      }
    });

    if (!allCustomers.length) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = async value => {
    if (!value || typeof value !== 'object' || !value._id) {
      // prevents crash if value._id is not define
      return;
    }
    const customerCheck = await CUSTOMERS.getStripeCustomerById(value._id);
    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        selectedCustomer: customerCheck
      }
    });
    setCustomerInfo(customerCheck?.info || {});
  };

  const handleModal = (rigth, name) => {
    const modalName = adminRights.general.customers[rigth]
      ? name
      : UNAUTHORIZED;

    if (name === 'customers') {
      setCustomerInfo({});
    }
    setModal(modalName);
  };

  const getParent = async () => {
    const filteredParents = customers.reduce(
      (acc, curr) => {
        if (!curr?.parentId && curr._id !== selectedCustomer._id) {
          acc.push({
            key: curr._id,
            label: curr.info?.name
          });
        }
        return acc;
      },
      [{ key: null, label: 'Aucun' }]
    );

    const parent =
      customers.find(customer => customer._id === selectedCustomer.parentId) ||
      null;
    setParents(filteredParents);
    setSelectedParent({
      key: parent?._id || null,
      label: parent?.info?.name || 'Aucun'
    });
  };

  const getSubAccounts = async () => {
    const foundSubAccounts = customers.reduce((acc, curr) => {
      if (curr.parentId === selectedCustomer._id) {
        acc.push({
          ...curr,
          id: curr._id,
          name: curr.info?.name,
          displayedMargin: curr.margin?.rate
            ? numeral(curr.margin.rate).format('0,0%')
            : 'N/A',
          subscription: curr.contribution?.default
            ? numeral(curr.contribution.default).format('0,0%')
            : 'Par défaut'
        });
      }
      return acc;
    }, []);

    const subCustommers = await Promise.all(
      foundSubAccounts.map(async customer => {
        const myBalance = await CUSTOMERS.getBalance(customer.id);
        if (customer.paymentMethod !== 'platform') {
          return {
            ...customer,
            balance: numeral(myBalance).format('0,0$')
          };
        }
        return { ...customer, balance: numeral(myBalance).format('0,0[.]00$') };
      })
    );

    setSubAccounts(subCustommers);
  };

  const getNumberOfGroupes = async presetId => {
    const numberOfGroupesResult = await GROUP.getGroups({
      presetId
    });
    setNumberOfGroupes(numberOfGroupesResult.length || 0);
  };

  const getFinancialAdmins = async () => {
    const usersByGroupId = await CUSTOMERS.getUsersByGroupid(
      selectedCustomer.groupId
    );

    const financialAdmins =
      selectedCustomer?.financialAdmins?.reduce((acc, curr) => {
        const userByGroup = usersByGroupId.find(u => u._id === curr);
        if (userByGroup) {
          acc.push(userByGroup);
        }
        return acc;
      }, []) || [];

    setAllUsers(usersByGroupId);
    setFinancialAdministrators(financialAdmins);
  };

  const getPresetData = async presetId => {
    const channels = await ACTIVATION.getConstraintsChannels(presetId);
    const fees = await ACTIVATION.getFees(presetId);
    const constraintResult = await ACTIVATION.getConstraints(presetId);
    const groupedChannelsByFee = channels.reduce((acc, curr) => {
      const { fee, channel } = curr;
      const { technicalFees, setUpFees, weeklyFees, dataCpm } = fees.find(
        f => f.id === fee
      );
      if (!acc[fee]) {
        acc[fee] = [];
      }
      acc[fee].push({
        fee,
        channel,
        technicalFees,
        setUpFees,
        weeklyFees,
        dataCpm
      });
      return acc;
    }, {});

    const mapped = Object.entries(groupedChannelsByFee).map(([key, value]) => {
      const {
        technicalFees,
        setUpFees,
        weeklyFees,
        dataCpm
      } = groupedChannelsByFee[key][0];
      return {
        _id: key,
        id: key,
        channels: value.map(v => v.channel).join(', '),
        technicalFees: technicalFees * 100,
        setUpFees,
        weeklyFees,
        dataCpm
      };
    });
    const preset = presets.find(p => p._id === presetId);
    setSelectedPreset({
      key: presetId,
      name: preset?.name,
      isDefault: preset?.isDefault
    });
    setConstraint(constraintResult[0]);
    setAllFees(mapped);
  };

  const getGroupCustomLabel = async id => {
    const found = await GROUP.getGroups({
      _id: id,
      custom: ['key', 'customerName', 'name']
    });
    const rlt = parseCustomLabel(found[0]);
    setCustomLabel(rlt);
  };

  const initializeCutomerData = async () => {
    if (customers.length) {
      setIsLoading(true);
      await getParent();
      await getSubAccounts();

      const presetId = await CUSTOMERS.getPresetId(selectedCustomer?.groupId);
      setAutoSendInvoice(selectedCustomer?.autoSendInvoice);

      await getNumberOfGroupes(presetId);
      await getFinancialAdmins();
      await getGroupCustomLabel(selectedCustomer?.groupId);
      await getPresetData(presetId);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (customers.length && selectedCustomer) {
      initializeCutomerData();
    } else {
      setCustomerInfo({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, selectedCustomer]);

  const updateCustomer = async () => {
    if (!adminRights.general?.customers?.update) {
      setModal(UNAUTHORIZED);
      return;
    }

    const body = {
      ...selectedCustomer,
      parentId: selectedParent?.key,
      financialAdmins: financialAdministrators.map(admin => admin._id),
      info: customerInfo,
      offPlatformPayment: paymentMethod === 'off_platform',
      budgetAlerts: { emails: emailsToAlert, budget: budgetAlerts },
      autoSendInvoice
    };

    const result = await Promise.all([
      CUSTOMERS.updateCostumers(selectedCustomer._id, body),
      GROUP.updateV2(selectedCustomer.groupId, {
        presetId: selectedPreset?.key
      }),
      ACTIVATION.updateManyFees(
        allFees.map(fee => ({
          _id: fee._id,
          technicalFees: fee.technicalFees / 100,
          setUpFees: fee.setUpFees,
          weeklyFees: fee.weeklyFees,
          dataCpm: fee.dataCpm
        }))
      ),
      ACTIVATION.updateConstraints(constraint._id, {
        maximumDataCost: constraint?.maximumDataCost
      })
    ]);

    const customerUpdatRes = result[0];

    if (customerUpdatRes?.stripeError) {
      setErr({ show: true, ...customerUpdatRes });
    } else {
      const newCustomers = customers.map(customer => {
        if (customer._id === selectedCustomer._id) {
          return {
            ...customer,
            ...customerUpdatRes,
            parentId: selectedParent?.key
          };
        }
        return customer;
      });
      dispatchCustomer({
        type: CustomerActionsTypes.UPDATE_CUSTOMER,
        props: {
          customers: newCustomers,
          selectedCustomer: {
            ...selectedCustomer,
            ...result[0],
            parentId: selectedParent?.key
          }
        }
      });
    }
  };

  const handleFeesChange = (e, index, key) => {
    const newFees = [...allFees];
    newFees[index][key] = e;
    setAllFees(newFees);
  };

  const handleCheck = items => {
    setSelectedSubAccounts(
      items.map(id => {
        return customers.find(c => c._id === id);
      })
    );
  };

  const handleOpenUpdateChild = (rigth, name, rows) => {
    if (!adminRights.general?.customers[rigth]) {
      setModal(UNAUTHORIZED);
      return;
    }
    setSelectedSubAccounts(
      rows.map(row => {
        return customers.find(c => c._id === row.id);
      })
    );
    setModal(name);
  };

  const handleChangePreset = async presetId => {
    const numberOfGroupesResult = await GROUP.getGroups({
      presetId
    });
    setNumberOfGroupes(numberOfGroupesResult.length || 0);
    await getPresetData(presetId);
  };

  const removeChild = async item => {
    await CUSTOMERS.updateCostumers(item.id, {
      parentId: ''
    });
    if (!adminRights?.general?.customers?.delete) {
      setModal(UNAUTHORIZED);
      return;
    }
    await CUSTOMERS.updateCostumers(item.id, {
      parentId: ''
    });

    const newCustomers = customers.map(customer => {
      if (customer.id === item._id) {
        return { ...customer, parentId: '' };
      }
      return customer;
    });

    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        customers: newCustomers
      }
    });
    getSubAccounts();
  };

  const checkFields = () => {
    return (
      !customerInfo?.name ||
      !customerInfo?.address?.country ||
      !customerInfo?.companyId ||
      !customerInfo?.address?.city ||
      !customerInfo?.address?.country ||
      (customerInfo.email?.length && !isValidEmail(customerInfo.email))
    );
  };

  const deleteCustomer = async () => {
    try {
      await CUSTOMERS.deleteCustomer(selectedCustomer._id);
      await initContext();
      setModal('');
    } catch (error) {
      console.error('Erreur lors de la suppression du client', error);
    }
  };

  const canDelete = adminRights?.general?.customers?.advanced;

  const fetchBalance = async () => {
    if (selectedCustomer) {
      const customerBalance = await CUSTOMERS.getBalance(selectedCustomer._id);
      setBalance(customerBalance);
    }
  };

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line
  }, [selectedCustomer, paymentMethod]);

  useEffect(() => {
    if (selectedCustomer?.offPlatformPayment) {
      setPaymentMethod('off_platform');
    } else {
      setPaymentMethod('platform');
    }
  }, [selectedCustomer]);

  const handlePaymentMethodChange = method => {
    setPaymentMethod(method.key);
  };

  const validateBalance = inputValue => {
    const inputBalance = inputValue || '';
    setNewBalance(inputBalance);
    const valid =
      !Number.isNaN(parseFloat(inputBalance)) && parseFloat(inputBalance) >= 0;
    setIsValid(valid);
  };

  const validateBudgetAlert = inputValue => {
    const inputBalance = parseFloat(inputValue) || 0;
    setBudgetAlert(inputBalance);
  };

  const handleSaveBalance = async () => {
    const updatedBalance = parseFloat(newBalance);
    if (!isValid || Number.isNaN(updatedBalance)) {
      return;
    }

    const transactionAmount = updatedBalance - balance;

    await TRANSACTION.create({
      stripeCustomerId: selectedCustomer._id,
      amount: transactionAmount
    });

    setBalance(updatedBalance);
    setIsEditingBalance(false);
  };

  const canEditPreset = isSuperAdmin || !selectedPreset?.isDefault;

  return (
    <Loading loading={isLoading}>
      <Container>
        {modal === UNAUTHORIZED && (
          <UnauthorizedModal open onClose={() => setModal('')} />
        )}
        {modal === 'childcustomers' && (
          <UpdateChildCustomer
            selectedSubAccounts={selectedSubAccounts}
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {modal === 'delete' && (
          <DeleteCustomerModal
            open
            onClose={() => setModal('')}
            onConfirm={deleteCustomer}
            title="Suppression de compte client"
            description={`Après cette manipulation, le compte client : ${selectedCustomer?.info?.name} n'existera plus et les utilisateurs ne pourront plus lancer de campagne ou d'enrichissements CRM `}
          />
        )}
        {modal === 'customers' && (
          <CreateCustomerModal
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {modal === 'subaccounts' && (
          <AddChildCustomer
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {customers.length === 0 ? (
          <Row>
            <Item>
              <Text variant="h5" fontWeight={700}>
                Pour créer un client, cliquer sur le boutton ci-dessous
              </Text>
            </Item>
            <Item justify="center">
              <ButtonCreate
                justify="center"
                onClick={() => handleModal('create', 'customers')}
                text="Créer un Compte"
              />
            </Item>
          </Row>
        ) : (
          <>
            <Row justify="flex-end">
              <Item flex>
                <ButtonCreate
                  onClick={() => handleModal('create', 'customers')}
                  text="Ajouter un compte"
                />
              </Item>
            </Row>
            <Row>
              <Item justify="flex-start">
                <Container>
                  <Row justify="flex-start">
                    <Item xs={4} justify="flex-start">
                      <AutocompleteTextField
                        onChange={handleSelect}
                        options={customers}
                        formatLabel={option => option?.info?.name}
                        title="Compte"
                        value={selectedCustomer}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            <Text fontSize="14px" noWrap>
                              {item?.info?.name}
                            </Text>
                          </li>
                        )}
                      />
                    </Item>
                    <Item xs={4} justify="flex-start">
                      <AutocompleteTextField
                        onChange={e => {
                          if (e === null) {
                            setSelectedParent(null);
                          } else {
                            setSelectedParent({
                              key: e?.key,
                              label: e?.label
                            });
                          }
                        }}
                        toolTipText={
                          isParent(selectedCustomer, customers)
                            ? `${selectedCustomer?.info?.name} est dejà parent`
                            : null
                        }
                        disabled={isParent(selectedCustomer, customers)}
                        options={parents}
                        formatLabel={option => option?.label}
                        value={selectedParent}
                        title="Compte Parent"
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            <Text fontSize="14px" noWrap>
                              {item?.label}
                            </Text>
                          </li>
                        )}
                      />
                    </Item>
                  </Row>
                  <Row justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Row justify="flex-start">
                        <Text style={{ whiteSpace: 'nowrap' }}>
                          {
                            'Liens: client (nom) < organisation (nom) > PML groupements / magasins (key) '
                          }
                        </Text>
                        {customLabel?.length ? (
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {customLabel}
                          </Text>
                        ) : (
                          <Text>Aucun</Text>
                        )}
                      </Row>
                    </Item>
                  </Row>
                </Container>
              </Item>
            </Row>

            <Row>
              <Item justify="flex-start">
                <FormSection title="Conditions tarifaires" xs={3}>
                  <Container>
                    <Item justify="flex-start">
                      <Row justify="flex-start" alignItems="center">
                        <Item xs={4} justify="flex-start">
                          <AutocompleteTextField
                            onChange={e => {
                              handleChangePreset(e?.id);
                            }}
                            options={presets}
                            formatLabel={option => option?.name}
                            value={selectedPreset}
                            title="Preset appliqué"
                          />
                        </Item>
                        <Item xs={3} justify="flex-start">
                          <Text
                            color={themeColors.themeV2.notValidated}
                            italic
                            style={{
                              marginTop: '40px',
                              marginLeft: '20px'
                            }}
                          >
                            partagé avec {numberOfGroupes} autres organisations
                          </Text>
                        </Item>
                        <Item xs={4} justify="flex-start">
                          <TextField
                            style={{
                              marginLeft: 'auto'
                            }}
                            unit="%"
                            format="numberDec"
                            value={(constraint?.maximumDataCost || 0) * 100}
                            onChange={e => {
                              setConstraint({
                                ...constraint,
                                maximumDataCost: e / 100
                              });
                            }}
                            title="Frais data maximums"
                            disabled={!canEditPreset}
                          />
                        </Item>
                      </Row>
                    </Item>
                    <Item
                      justify="flex-start"
                      style={{
                        paddingRight: '40px',
                        paddingLeft: '40px'
                      }}
                    >
                      <EditableTable
                        headers={[
                          { key: 'channels', label: 'Canaux' },
                          {
                            key: 'technicalFees',
                            label: 'Frais techniques',
                            percent: true,
                            editable: true
                          },
                          {
                            key: 'setUpFees',
                            label: 'Frais de set up',
                            editable: true
                          },
                          {
                            key: 'weeklyFees',
                            label: 'Frais de trading',
                            editable: true
                          },
                          {
                            key: 'dataCpm',
                            label: (
                              <Item>
                                <Text>CPM data</Text>&nbsp;
                                <HelpTooltip title="Ce CPM est utile pour le calcul de la boucle de retour data. Aucun frais data ne sera appliqué lors de vos campagnes CRM." />
                              </Item>
                            ),
                            editable: true
                          }
                        ]}
                        values={allFees}
                        disabled={!canEditPreset}
                        handleChange={(e, index, key) =>
                          handleFeesChange(e, index, key)
                        }
                      />
                    </Item>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              <Item justify="flex-start">
                <FormSection title="Informations de facturation" xs={3}>
                  <Container>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <AutocompleteTextField
                          disabled={!isSuperAdmin}
                          required
                          options={[
                            { key: 'platform', label: 'Paiement plateforme' },
                            {
                              key: 'off_platform',
                              label: 'Paiement hors plateforme'
                            }
                          ]}
                          formatLabel={option => option.label}
                          value={
                            paymentMethod === 'platform'
                              ? {
                                  key: 'platform',
                                  label: 'Paiement plateforme'
                                }
                              : {
                                  key: 'off_platform',
                                  label: 'Paiement hors plateforme'
                                }
                          }
                          onChange={handlePaymentMethodChange}
                          title="Type de paiement"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <Box display="flex" alignItems="center">
                          {isEditingBalance &&
                          paymentMethod === 'off_platform' ? (
                            <TextField
                              value={newBalance}
                              format="numberDec"
                              unit="€"
                              onChange={value => {
                                validateBalance(value);
                              }}
                              title="Modifier le solde"
                            />
                          ) : (
                            <TextField
                              value={balance}
                              placeholder="Chargement..."
                              title="Solde"
                              unit="€"
                              format="numberDec"
                              disabled={
                                balance === null ||
                                paymentMethod === 'platform' ||
                                !isEditingBalance
                              }
                            />
                          )}
                          {paymentMethod === 'off_platform' && isSuperAdmin && (
                            <IconButton
                              onClick={() => {
                                if (isEditingBalance) {
                                  handleSaveBalance();
                                } else {
                                  setNewBalance(balance);
                                  setIsEditingBalance(true);
                                }
                              }}
                            >
                              {isEditingBalance ? (
                                <CheckIcon
                                  style={{
                                    color: isValid
                                      ? themeColors.themeV2.backoffice
                                      : themeColors.themeV2.notValidated,
                                    transform: 'translateY(5px)'
                                  }}
                                />
                              ) : (
                                <EditIcon
                                  style={{
                                    transform: 'translateY(5px)'
                                  }}
                                />
                              )}
                            </IconButton>
                          )}
                        </Box>
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.companyId}
                          value={customerInfo?.companyId || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              companyId: e
                            });
                          }}
                          title="SIRET"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <AutocompleteTextField
                          required
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                country: e.key
                              }
                            });
                          }}
                          error={!customerInfo?.address?.country}
                          options={countries}
                          formatLabel={option => option?.label}
                          value={
                            countries.find(
                              c =>
                                c.key.toLowerCase() ===
                                customerInfo?.address?.country.toLowerCase()
                            ) ||
                            countries.find(c =>
                              c.label
                                .toLowerCase()
                                .includes(
                                  customerInfo?.address?.country.toLowerCase()
                                )
                            ) ||
                            null
                          }
                          title="Pays ou région"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.name}
                          value={customerInfo?.name || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              name: e
                            });
                          }}
                          title="Raison sociale"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.address?.line1}
                          value={customerInfo?.address?.line1 || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                line1: e
                              }
                            });
                          }}
                          title="Adresse (ligne 1)"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.line2 || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                line2: e
                              }
                            });
                          }}
                          title="Adresse (ligne 2)"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.postalCode || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                postalCode: e
                              }
                            });
                          }}
                          title="Code postal"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          error={!customerInfo?.address?.city}
                          value={customerInfo?.address?.city || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                city: e
                              }
                            });
                          }}
                          title="Ville"
                          required
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.state || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                state: e
                              }
                            });
                          }}
                          title="Région"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.euVatNumber || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              euVatNumber: e
                            });
                          }}
                          title="Numéro de TVA intracommunautaire"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.email || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              email: e
                            });
                          }}
                          title="Email de facturation"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <Switch
                          onChange={setAutoSendInvoice}
                          checked={autoSendInvoice}
                          color={themeColors.primary}
                          title="Envoi automatique de facture"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <Switch
                          onChange={e => {
                            dispatchCustomer({
                              type: CustomerActionsTypes.UPDATE_CUSTOMER,
                              props: {
                                selectedCustomer: {
                                  ...selectedCustomer,
                                  activablePurchaseOrder: e
                                }
                              }
                            });
                          }}
                          disabled={
                            typeof selectedCustomer?.activablePurchaseOrder !==
                            'boolean'
                          }
                          checked={selectedCustomer?.activablePurchaseOrder}
                          color={themeColors.primary}
                          title="Bon de commande"
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              <Item>
                <FormSection title="Alertes de budgets" xs={3}>
                  <Container>
                    <Row justify="flex-start">
                      <Item xs={2} justify="flex-start">
                        <Switch
                          value={selectedCustomer?.budgetAlerting}
                          onChange={e => {
                            dispatchCustomer({
                              type: CustomerActionsTypes.UPDATE_CUSTOMER,
                              props: {
                                selectedCustomer: {
                                  ...selectedCustomer,
                                  budgetAlerting: e
                                }
                              }
                            });
                          }}
                          checked={selectedCustomer?.budgetAlerting}
                          color={themeColors.primary}
                          title="Alerting"
                          options={['Non', 'Oui']}
                        />
                      </Item>
                      {selectedCustomer?.budgetAlerting && (
                        <Item xs={3} justify="flex-start">
                          <TextField
                            value={selectedCustomer?.budgetAlerts?.budget}
                            format="numberDec"
                            unit="€"
                            onChange={value => {
                              validateBudgetAlert(value);
                            }}
                            title="Si solde inférieur ou égal à "
                            required
                          />
                        </Item>
                      )}
                      {selectedCustomer?.budgetAlerting && (
                        <Row justify="flex-start">
                          <Item xs={4} justify="flex-start">
                            <ListField
                              values={
                                selectedCustomer?.budgetAlerts?.emails?.map(
                                  el => ({ email: el })
                                ) || []
                              }
                              onChange={values => {
                                setEmailsToAlert([
                                  ...values
                                    .filter(el => el?.email)
                                    .map(el => el.email)
                                ]);
                              }}
                              actionTxt="Prévenir"
                              deleteAction
                              variant="customFields"
                              inputs={['TextField']}
                              labels={['Prévenir cet email']}
                              options={['email']}
                            />
                          </Item>
                        </Row>
                      )}
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              <Item>
                <FormSection title="Administrateurs financiers" xs={3}>
                  <Container>
                    <Item xs>
                      <ListField
                        options={allUsers}
                        searchOptionsKeys={['email']}
                        hideKeys={['_id', 'id']}
                        values={financialAdministrators}
                        onChange={setFinancialAdministrators}
                        actionTxt="Ajouter un administrateur"
                        selectNbMax={1}
                        deleteAction
                      />
                    </Item>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              {(isParent(selectedCustomer, customers) ||
                !selectedCustomer?.parentId) && (
                <Item>
                  <FormSection title="Sous-comptes" xs={3}>
                    <Container>
                      <Row>
                        <Item justify="flex-start">
                          <Text fontSize="14px" fontWeight={400}>
                            Sélectionnez ci-dessous les sous-comptes auxquels
                            vous voulez appliquer un prélèvement et de
                            l’abondement.
                          </Text>
                        </Item>
                      </Row>
                      <Row>
                        <Item xs>
                          <ButtonCreate
                            onClick={() => handleModal('create', 'subaccounts')}
                            text="Ajouter un compte"
                            justify="flex-start"
                          />
                        </Item>
                      </Row>
                      <Row>
                        <Item>
                          {subAccounts.length > 0 && (
                            <Table
                              headers={[
                                {
                                  id: 'name',
                                  label: 'Sous-compte'
                                },
                                {
                                  id: 'balance',
                                  label: 'Solde'
                                },
                                {
                                  id: 'subscription',
                                  label: 'Abondement',
                                  help:
                                    "L'abondement s'applique uniquement sur les campagnes prêtes à l'emploi"
                                },
                                {
                                  id: 'displayedMargin',
                                  label: 'Prélévement'
                                },
                                {
                                  id: 'actions',
                                  label: (
                                    <Button
                                      variant="contained"
                                      color={themeColors.themeV2.backoffice}
                                      onClick={() =>
                                        handleModal('update', 'childcustomers')
                                      }
                                      disabled={!selectedSubAccounts.length}
                                    >
                                      <Text color="inherit">
                                        Modifier le prélèvement et
                                        l&apos;abondement
                                      </Text>
                                    </Button>
                                  )
                                }
                              ]}
                              showCheckboxes
                              rows={subAccounts}
                              skipSlice
                              actions={[
                                {
                                  key: 'edit',
                                  icon: <EditIcon />,
                                  onClick: row =>
                                    handleOpenUpdateChild(
                                      'update',
                                      'childcustomers',
                                      [row]
                                    ),
                                  display: () => true
                                },
                                {
                                  key: 'delete',
                                  icon: <DeleteIcon />,
                                  onClick: row => removeChild(row),
                                  display: () => true
                                }
                              ]}
                              onCheck={handleCheck}
                            />
                          )}
                        </Item>
                      </Row>
                    </Container>
                  </FormSection>
                </Item>
              )}
            </Row>
            <Row>
              <Item xs justify="flex-end">
                <Button
                  color={themeColors.black}
                  onClick={updateCustomer}
                  variant="contained"
                  disabled={checkFields()}
                  disabledMessage="L’un des champs requis n’est pas renseigné"
                >
                  <SaveIcon />
                  &nbsp; Sauvegarder
                </Button>
              </Item>
              {canDelete && (
                <Item xs={6} justify="flex-start">
                  <Button
                    variant="contained"
                    color={themeColors.red}
                    onClick={() => setModal('delete')}
                  >
                    <DeleteIcon /> Supprimer
                  </Button>
                </Item>
              )}
            </Row>
            {err.show && (
              <Row>
                <Item>
                  <Alert
                    severity="error"
                    text={err.message}
                    onClose={() => {
                      setErr({ show: !err.show, ...err });
                    }}
                  />
                </Item>
              </Row>
            )}
          </>
        )}
      </Container>
    </Loading>
  );
};
