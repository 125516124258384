import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  funnelIdError: false
};

export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onValidate, onClose } = props;
  const { funnels = [], selected, allUsers = [] } = config;

  const initialState = {
    funnelId: selected?.funnelId,
    toleranceLimit: selected?.toleranceLimit || 10,
    emails: selected?.emails || [],
    sheetUrl: selected?.sheetUrl || '',
    type: selected?.type || 'budget',
    frequencyWeekly: selected?.frequencyWeekly || false,
    sendEmails: selected?.sendEmails ?? false
  };

  const [
    {
      funnelId,
      toleranceLimit,
      emails,
      sheetUrl,
      type,
      frequencyWeekly,
      sendEmails
    },
    setState
  ] = React.useState(initialState);
  const [{ funnelIdError }, setError] = React.useState(ErrorState);

  const resetStates = () => {
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => ({
      ...prevState,
      [itemKey]: text
    }));
  };

  const toggleTrackingType = () => {
    setState(prev => ({
      ...prev,
      type: type === 'budget' ? 'impression' : 'budget'
    }));
  };

  const toggleFrequency = () => {
    setState(prev => ({
      ...prev,
      frequencyWeekly: !frequencyWeekly
    }));
  };

  const toggleSendEmails = () => {
    setState(prev => ({
      ...prev,
      sendEmails: !prev.sendEmails
    }));
  };

  const onAutoCompliteChange = (data, reason) => {
    if (reason === 'select-option' || reason === 'selectOption') {
      handleChange('funnelId')(data?.id);
    }

    if (reason === 'clear') {
      handleChange('funnelId')('');
    }
  };
  const onNext = () => {
    let error = false;

    if (!funnelId) {
      toggleError('funnelId', true);
      error = true;
    }

    if (!error) {
      const isArray = Array.isArray(toleranceLimit);
      const toleranceLimits = isArray ? toleranceLimit : [toleranceLimit];

      const datas = {
        funnelId,
        sheetUrl,
        toleranceLimits,
        toleranceLimit,
        alerting: sendEmails,
        alertingFrequence: frequencyWeekly ? 'weekly' : 'daily',
        type,
        emails
      };

      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onNext}
            >
              {selected ? 'Modifier' : 'Ajouter'} la configuration
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const searchOptionsKeys = ['name', 'id'];

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Suivi">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={1} justify="flex-start">
                      <Item justify="flex-start">
                        <Text>Type de suivi *</Text>
                      </Item>
                    </Row>
                    <Row spacing={1} justify="flex-start">
                      <Item justify="flex-start">
                        <Text bold={type === 'budget'}>Budgétaire</Text>
                        <Switch
                          checked={type === 'impression'}
                          onChange={toggleTrackingType}
                        />
                        <Text bold={type === 'impression'}>Impressions</Text>
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={1} justify="flex-start">
                      <Item justify="flex-start">
                        <Text>Périodicité *</Text>
                      </Item>
                    </Row>
                    <Row spacing={1} justify="flex-start">
                      <Item justify="flex-start">
                        <Text bold={!frequencyWeekly}>Quotidienne</Text>
                        <Switch
                          checked={frequencyWeekly}
                          onChange={toggleFrequency}
                        />
                        <Text bold={frequencyWeekly}>Hebdomadaire</Text>
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={4} justify="flex-start">
                  <Item
                    xs={6}
                    justify="flex-start"
                    style={{ paddingLeft: 0, paddingRight: '32px' }}
                  >
                    <AutocompleteTextField
                      title="Funnel"
                      required
                      options={funnels}
                      formatLabel={option => {
                        let str = option[searchOptionsKeys[0]];
                        if (searchOptionsKeys[1]) {
                          str += ` (${option[searchOptionsKeys[1]]})`;
                        }
                        return str;
                      }}
                      small
                      value={funnels.find(el => el.id === funnelId)}
                      onChange={onAutoCompliteChange}
                      placeholder="Choisissez un funnel"
                      disabled={selected?.funnelId}
                      error={funnelIdError}
                      bgColor={themeColors.primary}
                    />
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <TextField
                      title="URL Google Sheet"
                      small
                      value={sheetUrl}
                      onChange={handleChange('sheetUrl')}
                      placeholder="Saisissez un URL"
                      bgColor={themeColors.primary}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Tolérance et alerting">
              <Container>
                <Row
                  spacing={1}
                  justify="flex-start"
                  style={{ marginBottom: '40px' }}
                >
                  <Item justify="flex-start">
                    <Text>Seuils de tolérance</Text>
                    <ListField
                      options={Array.from({ length: 11 }, (_, i) => ({
                        name: `${i * 10}`
                      }))}
                      searchOptionsKeys={['name']}
                      values={
                        toleranceLimit.map
                          ? toleranceLimit.map(e => ({ name: `${e}` }))
                          : [{ name: `${toleranceLimit}` }]
                      }
                      small
                      value={
                        toleranceLimit.map
                          ? toleranceLimit.map(e => ({ name: `${e}` }))
                          : [{ name: `${toleranceLimit}` }]
                      }
                      actionTxt="Ajouter un seuil"
                      deleteAction
                      onChange={value => {
                        const newValues = value.map(el =>
                          parseInt(el.name, 10)
                        );
                        handleChange('toleranceLimit')(
                          newValues.length === 1 ? newValues[0] : newValues
                        );
                      }}
                      placeholder="Choisissez un seuil"
                      bgColor={themeColors.primary}
                    />
                  </Item>
                </Row>
                <Row spacing={1} justify="flex-start">
                  <Item direction="column" alignItems="flex-start">
                    <Text>Activer les alertes mail</Text>
                    <Switch checked={sendEmails} onChange={toggleSendEmails} />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {sendEmails && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="Addresses d'alerting">
                <Container>
                  <Row spacing={1} justify="flex-start">
                    <Item xs={12} justify="flex-start">
                      <ListField
                        options={allUsers.map(e => ({
                          name: e
                        }))}
                        searchOptionsKeys={['name']}
                        values={emails.map(e => ({ name: e }))}
                        small
                        value={emails.map(e => ({ name: e }))}
                        actionTxt="Ajouter un mail"
                        deleteAction
                        onChange={value => {
                          const newEmails = value.map(el => el.name);
                          handleChange('emails')(newEmails);
                        }}
                        placeholder="Choisissez un mail"
                        bgColor={themeColors.primary}
                      />
                    </Item>
                  </Row>
                </Container>
              </FormSection>
            </Item>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
